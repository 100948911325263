<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Auditoria</h2>
      </div>
    </div>
    <div class="section-content">
      <div class="list-container">
        <div class="vld-parent">
          <loading
                  :active.sync="isLoading"
                  :can-cancel="false"
                  :is-full-page="fullPage"
          ></loading>
        </div>
        <div class="header-container shadow-sm p-3 mb-5 bg-white rounded">
          <b-form-radio-group
                  v-model="selected"
                  class="search-filter"
                  button-variant="outline-primary"
                  buttons
          >
            <b-form-radio value="user" v-b-tooltip.hover title="Usuário Responsável">Usuário</b-form-radio>
            <b-form-radio value="description" v-b-tooltip.hover title="Descrição da auditoria">Descrição</b-form-radio>
            <b-form-radio value="date" v-b-tooltip.hover title="Formato aceito: 15(dia), 2020, 15/01/2020">Data</b-form-radio>
          </b-form-radio-group>
          <div class="search-container">
            <b-input-group size="sm" class="mb-0">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input v-model="filterText" v-on:keyup.enter="onSearchClick()" type="search" placeholder="Buscar..."></b-form-input>
            </b-input-group>
          </div>
        </div>
        <b-table class="shadow-sm bg-white rounded" :fields="fields" :items="audits" :per-page="0" :current-page="currentPage" @sort-changed="sortChanged" stacked="md">
        </b-table>
        <div class="pagination-container shadow-sm bg-white rounded">
          <b-row>
            <b-col class="my-3">
              <b-pagination
                      @input ="updatePage()"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
              ></b-pagination>
            </b-col>
            <b-col  class="my-3">
              <b-form-select
                      style="min-width: 100px"
                      @input ="updatePage()"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
  import { userService } from '../services/user_service';
  import searchIcon from '@/assets/icons/magnify.svg';
  import eyeIcon from '@/assets/icons/eye.svg';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import moment from 'moment';
  import { authorizationService } from '../services/authorization.service';

  export default {
    components: {
      Loading
    },
    data () {
      return {
        searchIcon,
        eyeIcon,
        currentIndex: 0,
        videoElement: null,
        video: '@/assets/video.mp4',
        content: '',
        filterText: '',
        fields: [
          { key: 'login', label: 'Usuário', sortable: true, thStyle: { width: '150px' } },
          {
            key: 'description',
            label: 'Descrição',
            sortable: true,
            thStyle: { width: '650px' },
            formatter: (value, key, item) => {
              const defaultPermissions = authorizationService.defaultPermissions();
              for (const index of Object.keys(defaultPermissions)) {
                if (value && value.toString().includes(index)) {
                  value = value.replace(index, ' ' + defaultPermissions[index]);
                }
              }
              return value;
            }
          },
          {
            key: 'date',
            label: 'Data',
            thStyle: { width: '180px', minWidth: '180px' },
            sortable: true,
            formatter: (value, key, item) => {
              return moment.unix(value).format('DD/MM/YYYY HH:mm');
            },
            sortByFormatted: true
          }
        ],
        audits: [],
        pageInfo: {},
        isLoading: false,
        fullPage: true,
        selected: 'user',
        totalRows: 0,
        perPage: 15,
        mounted: false,
        currentPage: 1,
        pageOptions: [5, 10, 15, { value: 100, text: 'Mostrar 100' }]
      };
    },
    async mounted () {
      await this.loadAudit({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage) });
      this.mounted = true;
    },
    methods: {
      sortChanged (e) {
        this.loadAudit({ perPage: parseInt(this.perPage), currentPage: this.currentPage, sortBy: e.sortBy, sortDesc: e.sortDesc });
      },
      getFormatDate (value) {
        return Date(value);
      },
      updatePage () {
        if (this.mounted) {
          this.onSearchClick();
        }
      },
      onSearchClick () {
        if (this.mounted) {
          if (this.selected === 'user' && this.filterText.length > 2) {
            this.loadAudit({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), user: this.filterText.toLowerCase() });
          } else if (this.selected === 'date' && this.filterText.length > 1) {
            this.loadAudit({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), date: moment(this.filterText, 'DD/MM/YYYY HH:mm').unix() });
          } else if (this.selected === 'description' && this.filterText.length > 1) {
            let description = this.filterText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').toLowerCase();
            description = description.replace(/a/g, '[a,á,à,ä,â,ã]').replace(/e/g, '[e,é,ë,è,ê]').replace(/i/g, '[i,í,ï,ì,î]').replace(/o/g, '[o,ó,ö,ò,õ,ô]').replace(/u/g, '[u,ü,ú,ù,û]').replace(/c/g, '[c,ç]').replace(/n/g, '[n,ñ]');
            this.loadAudit({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), description: description });
          } else {
            this.loadAudit({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage) });
          }
        }
      },
      async loadAudit (params) {
        if (params.perPage >= 0 && params.currentPage >= 1) {
          this.isLoading = true;
          const result = await userService.audits(params);
          if (result && result.length > 0) {
            this.audits = result[0].elements;
            this.pageInfo = result[0].pageInfo;
            this.totalRows = this.pageInfo && this.pageInfo.length > 0 && this.pageInfo[0].count;
          }
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .section-title h2 {
    margin-left: 5px;
  }

  .section-content {
    display: flex;

    .search-text{
      height: 40px;
      font-weight: 700;
    }

    .list-container {
      margin-left: 20px;
      margin-right: 20px;
      flex: 1;

      .header-container {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F5F8FA;
        border-radius: 4px;
        h3 {
          margin: 0;
          font-size: 20px;
          font-weight: 400;
        }

        .search-container {
          margin-right: 4%;
        }
      }

      .table {
        margin-top: 15px;
        background-color: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 0px;
        /deep/thead th {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;
          background: #F5F8FA;
          color: #6278A3;
          width: 40%;

          border-radius: 4px 4px 0px 0px;
          padding: 0.5rem;
          padding-left: 24px;
        }

        /deep/tbody td {
          font-size: 14px;
          padding: 0.5rem;
          padding-left: 24px;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          color: #6278A3;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
      .pagination-container {
        background-color: #FFFFFF;
        display: flex;
        flex-flow: row;
        align-content: center;
        alignment: center;
        padding-left: 35%;
      }

      .search-filter {
        margin-left: 3%;
      }

      .item-table {
        border-radius: 4px;
        background-color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
</style>
